import { Select } from 'antd'
import { useTranslation } from 'next-i18next'
import { useAppSelector } from 'src/app/hooks'
import styles from './SortSelect.module.scss'

interface SortSelectProps {
  onChangeSort: (value: string) => void
}

const SortSelect = ({ onChangeSort }: SortSelectProps) => {
  const { t } = useTranslation()
  const { order } = useAppSelector((state) => state.filters)

  return (
    <div className={styles['select-recommended-container']}>
      <span>{t('filters.sort')} :</span>
      <Select
        defaultValue={order}
        bordered={false}
        value={order}
        className={styles['select-recommended']}
        onChange={onChangeSort}
        options={[
          { value: '-scoring', label: t('filters.recommended') },
          { value: '-price', label: t('filters.majorPrice') },
          { value: 'price', label: t('filters.minorPrice') },
          { value: 'name', label: 'A - Z' },
          { value: '-name', label: 'Z - A' }
        ]}
      />
    </div>
  )
}

export default SortSelect
