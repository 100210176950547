/* eslint-disable react-hooks/exhaustive-deps */
import { Checkbox, Collapse } from 'antd'
import React, { useEffect, useState } from 'react'
import { useAppSelector } from 'src/app/hooks'
import { getCountries } from 'src/features/projects/projectsAPI'
const { Panel } = Collapse

// Syles
import styles from './Continent.module.scss'
import Country from '../Country/Country'
import { useDispatch } from 'react-redux'
import { setCountries, setRefreshFilters } from 'src/features/filters/filtersSlice'
import { setDifferentCountries } from 'src/features/compensation/compensationSlice'
import { UpOutlined } from '@ant-design/icons'
import { useTranslation } from 'next-i18next'
import { checkCookies, trackEvent } from 'src/features/visitors/actions'

const Continent: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [listOfContinents, setListOfContinents] = useState<Array<string>>([])

  const loading = useAppSelector((state) => state.ui.loading)
  const countries = useAppSelector((state) => state.filters.countries)
  const differentCountries = useAppSelector((state) => state.compensation.differentCountries)
  const refreshFilters = useAppSelector(
    (state) => state.filters.refreshFilters
  )

  const getContinents = (arrayOfCountries) => {
    let continents = []
    arrayOfCountries.forEach((el) => {
      const isIn = continents?.find((cont) => cont === el.continent)
      if(!isIn){
        continents.push(el.continent)
      }
    })
    setListOfContinents(continents)
  }

  const onChangeContinent = (continent) => {
    const totalPerContinentSelected = countries.filter(el => el.continent == continent)
    const countriesSelected = countries.filter(el => el.continent !== continent)
    if(!countries){
      dispatch(checkCookies())
      dispatch(trackEvent('MARKET_HOME', 'ACTION_CLICK', 'FILTER_COUNTRY'))
    }
    if(totalPerContinentSelected.length  > 0){
      dispatch(setCountries(countriesSelected))
    }else{
      const totalPerContinent = differentCountries.filter(el => el.continent == continent)
      dispatch(setCountries([...countriesSelected, ...totalPerContinent]))
    }
    dispatch(setRefreshFilters(!refreshFilters))
  }

  const isCheckedContinent = (continent) => {
    const isIn = countries.find(el => el.continent == continent)
    if(isIn){
      return true
    }else{
      return false
    }
  }

  useEffect(() => {
    if (loading) {
      getCountries()
        .then((res) => {
          getContinents(res)
          dispatch(setDifferentCountries(res))
        })
        .catch((e) => console.warn(e))
    }
  }, [loading])

  return(
    <>
      {listOfContinents?.map((continent, index) => {
        return (
          <div className={styles['continent-container']} key={index}>
            <Checkbox
              className={styles['continent-checkbox']}
              checked={isCheckedContinent(continent)}
              onClick={() => onChangeContinent(continent)}
              key={index}
            />
            <Collapse
              className={styles['collapse-continent']}
              bordered={false}
              expandIcon={({ isActive }) => <UpOutlined  rotate={isActive ? 0 : 180} style={{ color: '#777E8B' }} /> }
              expandIconPosition='right'
              ghost={true}
              defaultActiveKey={[continent]}
            >

              <Panel
                header={
                  <p className={
                    isCheckedContinent(continent)
                      ? styles['continent-text-selected']
                      : styles['continent-text']
                  }>
                    {t(`continent.${continent.replace(/\s+/g, '').replace(/^./, (match) => match.toLowerCase())}`)}
                  </p>
                }
                className={styles['custom']}
                key={continent}
              >
                <Country continent={continent} />
              </Panel>
            </Collapse>

          </div>
        )
      })}
    </>
  )
}

export default Continent
