/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import styles from './Price.module.scss'
import { InputNumber, Slider } from 'antd'
import { useTranslation } from 'next-i18next'
import {
  setPrice,
  setPriceChange,
  setRefreshFilters,
} from 'src/features/filters/filtersSlice'
import { useAppSelector } from 'src/app/hooks'
import { useDispatch } from 'react-redux'
import { setFiltersKeys } from 'src/features/ui/uiSlice'
import { inverseMapToScale, validateOnlyNumbers } from 'src/helpers/utils'
import { checkCookies, trackEvent } from 'src/features/visitors/actions'

const Price: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const refreshFilters = useAppSelector(
    (state) => state.filters.refreshFilters
  )
  const price = useAppSelector((state) => state.filters.price)
  const priceChange = useAppSelector((state) => state.filters.priceChange)
  const currencySymbol = useAppSelector(
    (state) => state.compensation.currencySymbol
  )
  const maxPrice = useAppSelector((state) => state.compensation.maxPrice)
  const loadingSliderPrice = useAppSelector(
    (state) => state.ui.loadingSliderPrice
  )

  const [sliderValue, setSliderValue] = useState<Array<number>>([
    mapToScale(price[0], 0, maxPrice + 1),
    mapToScale(price[1], 0, maxPrice + 1),
  ])

  useEffect(() => {
    dispatch(setPriceChange(false))
    dispatch(setPrice([0.01, maxPrice]))
  }, [maxPrice])

  useEffect(() => {
    setSliderValue([
      mapToScale(price[0], 0, maxPrice + 1),
      mapToScale(price[1], 0, maxPrice + 1),
    ])
  }, [loadingSliderPrice])

  let timeoutFirst = null
  const onChangeFirstValue = (value) => {
    clearTimeout(timeoutFirst) // Clear any previous timeout
    timeoutFirst = setTimeout(() => {
      if (value > 0 && validateOnlyNumbers(value.toString())) {
        dispatch(setPrice([parseFloat(value), price[1]]))
      } else {
        dispatch(setPrice([0.01, price[1]]))
      }
      const inverseLogValue = inverseLogSlider(value, maxPrice)
      setSliderValue([
        mapToScale(inverseLogValue, 0, maxPrice + 1),
        sliderValue[1],
      ])
      if (value == 0 || value == null) {
        dispatch(setPriceChange(false))
      } else {
        if (!priceChange) {
          dispatch(checkCookies())
          dispatch(trackEvent('MARKET_HOME', 'ACTION_CLICK', 'FILTER_PRICE'))
        }
        dispatch(setPriceChange(true))
      }
      dispatch(setRefreshFilters(!refreshFilters))
    }, 800)
  }

  let timeoutSecond = null
  const onChangeSecondValue = (value) => {
    clearTimeout(timeoutSecond) // Clear any previous timeout
    timeoutSecond = setTimeout(() => {
      if (value > price[0] && validateOnlyNumbers(value.toString())) {
        const inverseLogValue = inverseLogSlider(value, maxPrice)
        dispatch(setPrice([price[0], value]))
        setSliderValue([
          sliderValue[0],
          mapToScale(inverseLogValue, 0, maxPrice + 1),
        ])
        dispatch(setFiltersKeys(['price']))
        dispatch(setRefreshFilters(!refreshFilters))
        if (value == maxPrice + 1 || value == null) {
          dispatch(setPriceChange(false))
        } else {
          if (!priceChange) {
            dispatch(checkCookies())
            dispatch(trackEvent('MARKET_HOME', 'ACTION_CLICK', 'FILTER_PRICE'))
          }
          dispatch(setPriceChange(true))
        }
      }
    }, 800) // Set the delay to 1.5 seconds
  }

  function logSlider(value: number, max: number): number {
    const minp = 0
    const maxp = maxPrice + 1
    const minv = 0
    const maxv = Math.log(max + 1)
    const scale = (maxp - minp) / (maxv - minv)

    return Math.floor(Math.exp((value - minp) / scale + minv)) - 1
  }

  function inverseLogSlider(value: number, max: number): number {
    const minp = 0
    const maxp = maxPrice + 1
    const minv = 0
    const maxv = Math.log(max + 1)
    const scale = (maxp - minp) / (maxv - minv)

    return Math.log(value + 1 - minv) * scale + minp
  }

  function mapToScale(number, minBound, maxBound) {
    // Calculate the range of the bounds
    const range = maxBound - minBound

    // Calculate the ratio of the number within the bounds
    const ratio = (number - minBound) / range

    // Scale the ratio to a value between 1 and 100
    const scaledRatio = ratio * 99 + 1
    // const scaledRatio = (ratio * 100) / ((maxBound - minBound) / 100);

    // Return the scaled ratio rounded to the nearest integer
    return Math.round(scaledRatio)
  }


  const onChangeRange = (value) => {
    let logValue = value
    logValue = value.map((v) => logSlider(v, maxPrice))

    if (logValue[0] > 0) {
      dispatch(setPrice(logValue))
      setSliderValue([
        mapToScale(value[0], 0, maxPrice + 1),
        mapToScale(value[1], 0, maxPrice + 1),
      ])
    } else {
      dispatch(setPrice([0.01, logValue[1]]))
      setSliderValue([
        mapToScale(0.01, 0, maxPrice + 1),
        mapToScale(value[1], 0, maxPrice + 1),
      ])
    }
    if (logValue[0] == 0 && logValue[1] >= maxPrice - 1) {
      dispatch(setPriceChange(false))
    } else {
      if (!priceChange) {
        dispatch(checkCookies())
        dispatch(trackEvent('MARKET_HOME', 'ACTION_CLICK', 'FILTER_PRICE'))
      }
      dispatch(setPriceChange(true))
    }
    dispatch(setRefreshFilters(!refreshFilters))
  }

  return (
    <div
      data-testid='price-filter'
      className={styles['price-filter-container']}
    >
      <div className={styles['input-container']}>
        <Slider
          tooltipVisible={false}
          trackStyle={[{ backgroundColor: '#1C64F2' }]}
          handleStyle={[{ border: 'solid 2px #1C64F2' }]}
          className={styles['slider']}
          range={{ draggableTrack: true }}
          value={[
            sliderValue[0] == 0.01
              ? 0.0
              : inverseMapToScale(sliderValue[0], 0, maxPrice + 1),
            inverseMapToScale(sliderValue[1], 0, maxPrice + 1),
          ]}
          onChange={(value) => {
            onChangeRange(value)
          }}
          max={maxPrice + 1}
        />
      </div>

      <div className={styles['container']}>
        <div className={styles['input-container']}>
          <p className={styles['text']}>{t('filters.from')}</p>
          <div className={styles['price-container']}>
            <InputNumber
              data-testid='price-since-test'
              type='number'
              controls={false}
              bordered={false}
              value={price[0] == 0.01 ? 0.0 : price[0]}
              step='0.00'
              onChange={(value) => onChangeFirstValue(value)}
              onPressEnter={(e) => e.preventDefault()}
              id={`price-since`}
              className={styles['price-input']}
            />
            <span>{currencySymbol}</span>
          </div>
        </div>
        <div className={styles['input-container']}>
          <p className={styles['text']}>{t('filters.to')}</p>
          <div className={styles['price-container']}>
            <InputNumber
              data-testid='price-until-test'
              type='number'
              controls={false}
              bordered={false}
              value={price[1]}
              onChange={(value) => onChangeSecondValue(value)}
              onPressEnter={(e) => e.preventDefault()}
              id={`price-until`}
              max={maxPrice + 1}
              step='0.00'
              className={styles['price-input']}
            />
            <span>{currencySymbol}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Price


