import { AppDispatch, AppState } from 'src/app/store'
import {
  setProject,
} from './projectSlice'
import {
  setCompensationTypes,
  setDifferentCategories,
  setEnergyTypes,
  setImpacts,
  setMaxPrice,
  setMaxStock,
  setMechanism,
  setRegistryTypes,
  setStandardTypes,
} from 'src/features/compensation/compensationSlice'
import {
  getExpensivePrice,
  getHigherStock,
  getOlderVintage,
  getNewestVintage
} from 'src/helpers/utils'
import { getProjects, getTypesCategories } from './projectsAPI'
import { setPrice, setStock } from 'src/features/filters/filtersSlice'
import { setYearSince } from 'src/features/filters/filtersSlice'
import { setYearUntil } from 'src/features/filters/filtersSlice'
import Cookies from 'js-cookie'

const getMechanism = (arrayOfProjects: Array<any>, dispatch: AppDispatch) => {
  let arrayOfMechanism = []
  const projectArray = arrayOfProjects?.map(
    (project: any) => project.mechanism
  )
  projectArray?.forEach((mechanism: string) => {
    if (
      !arrayOfMechanism.includes(mechanism) &&
      mechanism !== null &&
      mechanism !== ''
    ) {
      arrayOfMechanism.push(mechanism)
    }
  })
  dispatch(setMechanism(arrayOfMechanism.sort()))
}

const getImpacts = (arrayOfProjects: Array<any>, dispatch: AppDispatch) => {
  const projectArray = arrayOfProjects?.map(
    (project: any) => project.impacts
  )
  const cleanedProjectArray = projectArray.filter((imp) => imp.length > 0)
  const flattedArray = cleanedProjectArray.map((imp) => {
    const ar = imp.map((el) => el.name)
    return ar
  })
  const flattened = flattedArray.flatMap((name) => name)
  const removeDuplicatesArray = flattened.filter(
    (item, index) => flattened.indexOf(item) === index
  )
  dispatch(setImpacts(removeDuplicatesArray.sort()))
}

const getFilteringOptions = (arrayOfProjects: Array<any>, dispatch: AppDispatch) => {
  const arrayOfStandards = []
  const arrayOfEnergy = []
  const arrayOfCompensation = []
  const arrayOfRegistry = []
  getImpacts(arrayOfProjects, dispatch)
  getMechanism(arrayOfProjects, dispatch)

  arrayOfProjects?.forEach((project: any) => {
    const standard = project.standard
    const energy = project.energy_type
    const compensationType = project.type
    const registry = project.registry_depositing_name
    if (!arrayOfEnergy.includes(energy) && energy) {
      arrayOfEnergy.push(energy)
    }
    if (!arrayOfRegistry.includes(registry) && registry) {
      arrayOfRegistry.push(registry)
    }
    if (!arrayOfStandards.includes(standard) && standard) {
      arrayOfStandards.push(standard)
    }
    if (!arrayOfCompensation.includes(compensationType) && compensationType) {
      arrayOfCompensation.push(compensationType)
    }
  })
  dispatch(setRegistryTypes(arrayOfRegistry))
  dispatch(setStandardTypes(arrayOfStandards))
  dispatch(setEnergyTypes(arrayOfEnergy))
  dispatch(setCompensationTypes(arrayOfCompensation))
}

const removeDuplicateOptions = (options: any[], validTypes: Set<string>) => {
  const uniqueValues = new Set()
  return options.filter(option => {
    const isValid = validTypes.has(option.name) && !uniqueValues.has(option.value)
    if (isValid) {
      uniqueValues.add(option.value)
      return true
    }
    return false
  })
}

export const initializeProjectData = () => async (dispatch: AppDispatch, getState: () => AppState) => {
  const { filters: { order, sessionLoaded, price, stock, yearSince, yearUntil } } = getState()

  const loadingFilters = sessionStorage.getItem('filters') &&
      sessionStorage.getItem('filters') != 'null' && !sessionLoaded
  const saveOrder = loadingFilters
    ? JSON.parse(sessionStorage.getItem('filters')).order
    : order
  const profileCookie = Cookies.get('ct-profile')
  const project = await getProjects(saveOrder, profileCookie)
  dispatch(setProject(project))
  getFilteringOptions(project.results, dispatch)
  dispatch(setMaxPrice(getExpensivePrice(project.results).price))
  dispatch(setMaxStock(getHigherStock(project.results)))

  if (!loadingFilters) {
    if (price[1] == null) {
      dispatch(setPrice([price[0], getExpensivePrice(project.results).price]))
    }
    if (stock[1] == null) {
      dispatch(setStock([stock[0], getHigherStock(project.results)]))
    }
    if (yearSince == null) {
      dispatch(setYearSince(getOlderVintage(project.results)))
    }
    if (yearUntil == null) {
      const currentYear = new Date().getFullYear()
      const newestVintageYear = getNewestVintage(project.results)
      dispatch(setYearUntil(Math.max(currentYear, newestVintageYear)))
    }
  }
}

export const initializeCategories = () => async (dispatch: AppDispatch) => {
  try {
    const res = await getTypesCategories()
    const data = res.data
    const validTypeNames = new Set(['type', 'energy_source_type'])

    if (data?.[0]?.options?.length) {
      const processedData = data.map(category => ({
        ...category,
        options: removeDuplicateOptions(category.options, validTypeNames)
      }))

      dispatch(setDifferentCategories(processedData))
    }
  } catch (e) {
    console.error(e)
  }
}
