import { useTranslation } from 'next-i18next'

const PageMeta = () => {
  const { t, i18n } = useTranslation()

  return (
    <>
      <link rel='shortcut icon' type='image/x-icon' href='/results/images/favicon.ico' sizes='any' />
      <link
        rel='alternate'
        hrefLang={i18n.language}
        href={`https://market.climatetrade.com/results${i18n.language !== 'en' ? '/' + i18n.language : '/'}`}
      />
      <link rel='alternate' hrefLang='x-default' href='https://market.climatetrade.com/results' />
      <meta name='viewport' content='initial-scale=1.0, width=device-width' />
      <meta name='google-site-verification' content='caXvb9QYJOiyPr_cV3j8_2AR0wBrOrr6Zkwb9T24GWs' />
      <meta name='description' content={t('seo.description')} />
      <meta property='og:type' content='website' />
      <meta property='og:title' content={t('seo.ogTitle')} />
      <meta property='og:description' content={t('seo.description')} />
      <meta property='og:image' content='https://climatetrade.com/wp-content/uploads/2021/04/climatetrade-paisaje-parallax.jpg' />
      <meta property='og:url' content='https://market.climatetrade.com/' />
      <meta name='theme-color' content='#003E6F' />
    </>
  )
}

export default PageMeta
