import type { NextPage } from 'next'
import Image from 'next/image'
import Head from 'next/head'
import React, { memo, useEffect, useRef, useState } from 'react'
import Cookies from 'js-cookie'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { useTranslation } from 'next-i18next'
import { useAppSelector, useAppDispatch } from 'src/app/hooks'
import { useRouter } from 'next/router'
import styles from '../assets/styles/04_page/index.module.scss'
import Background from 'src/components/Background/Background'
import InfiniteScroll from 'react-infinite-scroll-component'
import { getPortfolios } from 'src/features/projects/projectsAPI'
import { setPortfolios } from 'src/features/projects/projectSlice'
import { getProjectsTags, getSocialProof } from 'src/features/compensation/compensationAPI'
import {
  setCurrencySymbol,
  setProjectTags,
} from 'src/features/compensation/compensationSlice'
import { Button, Col, Row, message } from 'antd'
import Search from 'src/components/Search/Search'
import UploadProject from 'src/components/UploadProject/UploadProject'
import Filters from 'src/components/Filters/Filters'
import CategoriesPreFilter from 'src/components/CategoriesPreFilter/CategoriesPreFilter'
import {
  setOrder,
  setRefreshFilters,
} from 'src/features/filters/filtersSlice'
import { shuffle } from 'src/helpers/utils'
import { ls_currency_code_name } from 'src/static/models/localstorage.names'
import SkeletonCategoryProjects from 'src/components/SkeletonCategoryProjects/SkeletonCategoryProjects'
import SkeletonPreFiltersMobile from 'src/components/SkeletonPreFiltersMobile/SkeletonPreFiltersMobile'
import SkeletonFilters from 'src/components/SkeletonFilters/SkeletonFilters'
import CardProjects from 'src/components/CardProjects/CardProjects'
import ModalFilter from 'src/components/Filters/ModalFilters/ModalFilters'
import { checkCookies, trackEvent } from 'src/features/visitors/actions'
import menuOutlined from '../assets/img/menuOutlined.png'
import plant from '../assets/img/plant.png'
import CardDidYouKnowThat from 'src/components/CardDidYouKnowThat/CardDidYouKnowThat'
import ExitIntentPopup from 'src/components/ExitIntentPopup/ExitIntentPopup'
import WhatsNewBanner from 'src/components/WhatsNewBanner/WhatsNewBanner'
import { setReferralCodeCookie } from 'src/helpers/cookies.helpers'
import PopularFilters from 'src/components/Filters/PopularFilters/PopularFilters'

import CardPortfolio from 'src/components/CardProjects/CardPortfolio'
import SortSelect from 'src/components/SortSelect/SortSelect'
import PageMeta from 'src/components/PageMeta/PageMeta'
import { initializeProjectData, initializeCategories } from 'src/features/projects/actions'
import { applyFilters, handleQueryFilters, orderArray } from 'src/features/filters/actions'

const didYouKnowCardsQuantityToShow = 5
const didYouKnowCardsQuantity = 9
const currencyCodeEnv = process.env.CURRENCY_CODE
const openNotification = (description: string, notificationTitle: string) => {
  message.success({
    content: (
      <Row>
        <Col span={1} />
        <Col span={20}><p className={styles['description']}>{description}</p></Col>
      </Row>
    ),
    className: styles['notification'],
    duration: 6,
    icon: (
      <Row>
        <Col span={1}><Image src={plant} alt='Plant' /></Col>
        <Col span={20}><h5 className={styles['notificationTitle']}>{notificationTitle}</h5></Col>
        <Col span={1} />
      </Row>
    )
  })
}

const getRandomIndex = (max: number) => {
  const randomBuffer = new Uint32Array(1);
  crypto.getRandomValues(randomBuffer);
  return randomBuffer[0] % max;
}

const HomePage: NextPage = () => {
  const { t, i18n } = useTranslation()
  const router = useRouter()
  const dispatch = useAppDispatch()

  const { project: projects, reloadProject: reloadProjects } = useAppSelector((state) => state.project)

  const {
    currencies,
    currency_code: currencyCode,
  } = useAppSelector((state) => state.compensation)

  const {
    order,
    sessionLoaded: filtersLoaded,
    queryRead,
    filteredProjects,
    refreshFilters,
  } = useAppSelector((state) => state.filters)

  const { user, isUserLogged } = useAppSelector((state) => state.user)

  const { loading } = useAppSelector((state) => state.ui)

  const [projectList, setProjectList] = useState<any[]>([])
  const [portfolioList, setPortfolioList] = useState<any[]>([])
  const [mobileProjectList, setMobileProjectList] = useState<any[]>([])
  const [showMore, setShowMore] = useState<boolean>(false)
  const [inMobile, setInMobile] = useState<boolean>(false)
  const [page, setPage] = useState(0)
  const [totalProjects, setTotalProjects] = useState<number>(1)
  const [remainingCards, setRemainingCards] = useState<number>(0)
  const [width, setWidth] = useState<number>(0)
  const [firstTime, setFirstTime] = useState<boolean>(true)
  const [showTabletFilter, setShowTabletFilter] = useState<boolean>(false)
  const [didYouKnowCards, setDidYouKnowCards] = useState<number[]>([])
  const [stopButtonApplyFilter, setStopButtonApplyFilter] = useState<boolean>(false)
  const buttonApplyFilterRef = useRef(null)
  const filterColumnRef = useRef<HTMLDivElement>(null)

  const isPortfolio =(p)=>Object.hasOwn(p, 'commercial_name')

  const queryFilters = async () => {
    dispatch(handleQueryFilters(router.query))
  }

  useEffect(() => {
    if(projects) { dispatch(applyFilters()) }
  }, [queryRead, projects, refreshFilters])

  useEffect(() => {
    queryFilters()

    window.addEventListener('popstate', queryFilters)
    return () => {
      window.removeEventListener('popstate', queryFilters)
    }
  }, [projects])


  useEffect(() => {

    if (loading) {
      let selectedCurrencyCode = currencyCodeEnv
      if (isUserLogged) {
        if (user.user.currency_token_code) {
          selectedCurrencyCode = user.user.currency_token_code
        }
      } else {
        const currencyCodeStorage = localStorage.getItem(
          ls_currency_code_name
        )
        if (currencyCodeStorage) {
          selectedCurrencyCode = currencyCodeStorage
        }
      }

      const currencyUser = currencies.find(
        (el) => el.code === selectedCurrencyCode
      )
      dispatch(setCurrencySymbol(currencyUser?.symbol))

      if (router.query.profile) {
        const exist = Cookies.get('ct-profile')
        if (exist) {
          Cookies.remove('ct-profile')
        }
        Cookies.set('ct-profile', router.query.profile as string, {
          expires: new Date(Date.now() + 2700000)
        })
      }


      dispatch(initializeProjectData())

      dispatch(initializeCategories())

      if (localStorage.getItem('trakingSearch')) {
        dispatch(checkCookies())
        dispatch(
          trackEvent(
            'MARKET_HOME',
            'ACTION_CLICK',
            'HEADER_SEARCH_BOX',
            localStorage.getItem('trakingSearch')
          )
        )
        localStorage.removeItem('trakingSearch')
      }
      getPortfolios().then(portfolios => {
        dispatch(setPortfolios(portfolios))
        setPortfolioList(portfolios)
      })
      getProjectsTags()
        .then((projectTags) => dispatch(setProjectTags(projectTags)))
        .catch((e) => console.error(e))

      if (firstTime) {
        getSocialProof()
          .then((res) => {
            const randomPos = getRandomIndex(5);
            setTimeout(() => { openNotification(res[randomPos], t('notification.title')) }, 15000)
            setFirstTime(false)
          })
          .catch((e) => console.error(e))
      }
    }
  }, [loading, currencyCode])

  function storePathValues(path: string) {
    const storage = sessionStorage
    if (!storage) return
    // Set the previous path as the value of the current path.
    const prevPath = storage.getItem('currentPath')

    if (!storage.getItem('prevPath')) {
      storage.setItem('prevPath', null)
    }
    if (`${path}` !== prevPath) {
      storage.setItem('prevPath', prevPath)
    }
    // Set the current path value by looking at the browser's location object.
    storage.setItem('currentPath', `/results${path}`)
  }

  useEffect(() => {
    const { referral_code } = router.query
    if (referral_code) setReferralCodeCookie(referral_code)
    storePathValues(router.asPath)
  }, [router.asPath, router.query])

  useEffect(() => localStorage.setItem('prevRoute', `${router.asPath}`), [router])

  const handleChangeSort = (value) => {
    dispatch(setOrder(value))
    dispatch(setRefreshFilters(!refreshFilters))
  }

  useEffect(() => {
    if (filtersLoaded) {
      const history = sessionStorage.getItem('history')
        ? JSON.parse(sessionStorage.getItem('history'))
        : []

      if (!history.includes(router.asPath)) {
        const newHistory = []

        history.forEach((route) => {
          if (!newHistory.includes(route)) {
            newHistory.push(route)
          }
        })

        newHistory.push(router.asPath)

        sessionStorage.setItem('history', JSON.stringify(newHistory))
      }
    }

    window.history.pushState(window.location.pathname, 'Title', `/results/${i18n.language}${router.asPath}`)
  }, [router.asPath, filtersLoaded])

  // infinite scrolling
  useEffect(() => {
    setProjectList([]) // reset projectList when filters change

    // se obtienen todos los id de las cards didYou que se van a mostrar y se desordena la lista de forma random
    const newDidYouKnowCards = shuffle(
      Array.from(Array(didYouKnowCardsQuantity), (_, index) => index + 1)
    ).slice(0, didYouKnowCardsQuantityToShow)

    setTotalProjects(filteredProjects?.length)
    if (!reloadProjects && filteredProjects) {
      const itemsPerPage = 18
      const startIndex = 0
      const endIndex = itemsPerPage
      const newList = [...filteredProjects.slice(startIndex, endIndex)]

      setProjectList(addCardDidYouKnowThat(newList, newDidYouKnowCards))

      setPage(1)
    }

    setMobileProjectList(filteredProjects?.slice(0, 4))
  }, [filteredProjects, refreshFilters])

  const handleLoadCards = () => {
    if (!reloadProjects && filteredProjects) {
      const itemsPerPage = 18
      const startIndex = page * itemsPerPage -
        (didYouKnowCardsQuantityToShow - didYouKnowCards.length)
      const endIndex = startIndex + itemsPerPage

      let newList = [...filteredProjects.slice(startIndex, endIndex)]

      const uniqueValues = {}
      newList = newList.filter(function (project) {
        if (!uniqueValues[project.id]) {
          uniqueValues[project.id] = true
          return true
        }
      })
      const filteredList = newList.filter((project) => {
        return !projectList.some((item) => item.id === project.id)
      })
      setProjectList(addCardDidYouKnowThat([...projectList, ...filteredList]))
      setPage(page + 1)
    }
  }

  const addCardDidYouKnowThat = (list, didCards?) => {
    const newProjectList = [...list]
    const newCardList = didCards ? [...didCards] : [...didYouKnowCards]
    const newCardSaved = []

    let count = didYouKnowCardsQuantityToShow

    for (let i = 1; i < didYouKnowCardsQuantityToShow + 1; i++) {
      if (newProjectList.length >= i * 8 && newCardList.length === count) {
        const completeRows = Math.floor(newProjectList.length / 18)
        const remaining = 18 - (newProjectList.length - completeRows * 18)
        remaining === 18 && newCardSaved.push(newProjectList.pop())
        newProjectList.splice(i * 8 - 1, 0, newCardList.pop())
      }
      count--
    }
    newCardSaved.reverse()
    setDidYouKnowCards(newCardList)

    return newProjectList
  }


  const handleResize = () => {
    setWidth(window.innerWidth)
    setShowTabletFilter(false)
  }
  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [width])

  useEffect(() => {
    const handleScroll = () => {
      if (width < 1366 && showTabletFilter) {
        const columnBottom = filterColumnRef?.current?.getBoundingClientRect()
          .bottom
        const screenBottom = window.scrollY + window.innerHeight
        const buttonBottom = buttonApplyFilterRef?.current?.getBoundingClientRect().bottom + window.scrollY + 30
        const limit = columnBottom + window.scrollY

        if (!stopButtonApplyFilter && buttonBottom > limit) {
          buttonApplyFilterRef.current.style.position = 'unset'
          setStopButtonApplyFilter(true)
        } else if (stopButtonApplyFilter && screenBottom <= buttonBottom) {
          buttonApplyFilterRef.current.style.position = 'fixed'
          setStopButtonApplyFilter(false)
        }
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [showTabletFilter, stopButtonApplyFilter])

  const handleRemainingCards = () => {
    let col = showTabletFilter || (width < 980 && width > 694) ? 2 : 3

    const totalCards =
      didYouKnowCardsQuantityToShow -
      didYouKnowCards.length +
      totalProjects

    const completeRows = Math.floor(totalCards / col)
    const remaining = col - (totalCards - completeRows * col)

    setRemainingCards(remaining === col || inMobile ? 0 : remaining)
  }

  useEffect(() => {
    if (width > 694) {
      handleRemainingCards()
      setInMobile(false)
      setShowMore(true)
    } else if (width <= 694 && !inMobile) {
      setInMobile(true)
      setShowMore(false)
    }
  }, [width, filteredProjects, showTabletFilter, didYouKnowCards])

  const renderCard = (p, index) => {
    if (isPortfolio(p)) {
      return <CardPortfolio key={p.slug} portfolio={p} />
    }

    if (typeof p === 'number') {
      return (
        <Col className={styles['fade-in']} hidden={inMobile} key={index}>
          <CardDidYouKnowThat cardNumber={p} />
        </Col>
      )
    }

    return (
      <Col key={index}>
        <CardProjects project={p} currencyList={currencies} />
      </Col>
    )
  }

  const getListWidth = () => {
    let listWidth = '960px'

    if (width < 692) {
      listWidth = '360px'
    } else if (showTabletFilter || width < 980) {
      listWidth = '640px'
    }

    return getMaxMinWidth(listWidth)
  }
const getRowJustify = () => showTabletFilter ? 'space-between' : 'center'

  const renderEmptySpace = () => {
    const spaces = [] // crea un array para almacenar los elementos
    for (let i = 0; i < remainingCards; i++) {
      spaces.push(
        <Col key={i}>
          <div style={{ width: '288px', height: '312px', borderRadius: '8px', background: '#F5F5F5' }} />
        </Col>
      ) // agrega un elemento al array en cada iteración
    }
    return <>{spaces}</>
  }
  const getMaxMinWidth = (boxWidth:string)=>({ maxWidth: boxWidth, minWidth: boxWidth })
  const portfoliosAndProjects = orderArray((projectList|| []), order).concat(portfolioList || [])
  const mPortfoliosAndProjects = orderArray((mobileProjectList || []), order).concat(portfolioList || [])
  const showTabletFilterText = showTabletFilter ? ' Close filters' : ' Filters'
  return (
    <div data-testid='index-page-test' style={{ width: '100%' }}>
      <Head>
        <title>{t('seo.ogTitle')}</title>
        <PageMeta />
      </Head>

      <Row justify='center'>
        <Col span={24}>
          <Background />
          <Search />
        </Col>
      </Row>
      {!inMobile && (
        <div className={styles['container']}>
          <Row justify='center' className={styles['content']}>
            <PopularFilters queryFilters={queryFilters} mobile={inMobile} />
          </Row>
        </div>
      )}
      <div className={styles['container']}>
        <Row justify='center' className={styles['content']}>
          <Col>
            <Row wrap={false} justify='space-between' className={styles['mobile-container']}>
              {/* filtros desktop */}
              {width >= 1366 && (
                <Col className={styles['filter-section']}>
                  {projects ? (
                    <>
                      <Filters changeSort={handleChangeSort} />
                      <WhatsNewBanner banner='FOOTPRINT' />
                      <WhatsNewBanner banner='RFP' />
                    </>
                  ) : (
                    <SkeletonFilters />
                  )}
                </Col>
              )}

              {/* grid */}
              <Col className={styles['grid-column']}>
                {/* texto cantidad de proyectos, order y mobile filter */}
                <Row>
                  {projects && !reloadProjects ? (
                    <Col>
                      <Row>
                        <Col span={24} className={styles['filter-section-mobile']}>
                          <ModalFilter changeSort={handleChangeSort} />
                          {inMobile && (
                            <PopularFilters queryFilters={queryFilters} mobile={inMobile} />
                          )}
                        </Col>
                      </Row>

                      <Row justify='space-between' className={styles['title-container']} wrap={width < 980}>
                        {/* filtros tablet */}
                        {width < 1366 && width >= 980 && (
                          <Col className={styles['filter-section']}>
                            <div className={styles['iconFilterMobile']}>
                              <img src={menuOutlined.src} className={styles['menuOutlined']}
                                onClick={() => setShowTabletFilter(!showTabletFilter)}
                                alt='menu-icon' />
                              {showTabletFilterText}
                            </div>
                          </Col>
                        )}

                        <h3 className={styles['title']}>
                          {!reloadProjects && (filteredProjects?.length || projects?.length || portfolioList?.length || 0)} {t('titles.projects')}
                        </h3>

                        <SortSelect onChangeSort={handleChangeSort} />
                      </Row>

                      {/* lista de cards */}
                      <Row wrap={false} justify={getRowJustify()}>
                        {/* filtros tablet */}
                        {width < 1366 && (
                          <Col ref={filterColumnRef} className={styles['filter-section']} hidden={!showTabletFilter}>
                            {projects ? (
                              <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                <div>
                                  <Filters changeSort={handleChangeSort} />
                                  <WhatsNewBanner banner='FOOTPRINT' />
                                  <br />
                                  <WhatsNewBanner banner='RFP' />
                                </div>
                                <Button ref={buttonApplyFilterRef} className={styles['btnApply']} onClick={() => setShowTabletFilter(false)}>
                                      Apply filters
                                </Button>
                              </div>
                            ) : (
                              <SkeletonFilters />
                            )}
                          </Col>
                        )}
                        <Col style={getListWidth()}>
                          {!reloadProjects && showMore ? <InfiniteScroll
                            dataLength={projectList?.length}
                            next={handleLoadCards}
                            hasMore={projectList?.length <
                                      didYouKnowCardsQuantityToShow - didYouKnowCards.length + totalProjects}
                            loader={<SkeletonCategoryProjects />}
                            scrollThreshold={0.71}
                            scrollableTarget='scrollableDiv'
                            className={filteredProjects?.length < 3 ? styles['infinite-container-2'] : styles['infinite-container']}
                          >
                            <Row gutter={[32, 32]} className={styles['mobile-list']} style={getListWidth()}>
                              {portfoliosAndProjects?.map((p, index) => renderCard(p, index))}
                              {remainingCards > 0 && projectList?.length >= totalProjects && renderEmptySpace()}
                            </Row>
                          </InfiniteScroll>
                            : <Row className={styles['mobile-list-show']}>
                              {mPortfoliosAndProjects?.map((p, index) => renderCard(p, index))}
                              <button className={styles['showMoreButton']} onClick={() => setShowMore(true)}>
                                {t('category.showMore')}
                              </button>
                            </Row>
                          }
                        </Col>
                      </Row>
                    </Col>
                  ) : <Row>
                    <Col span={24}>
                      <div>
                        <div className={styles['containerSkeletonDesktop']}>
                          <SkeletonPreFiltersMobile />
                          <SkeletonCategoryProjects />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  }
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <Row justify='center' className={styles['container']}>
        <Col>{projects && <CategoriesPreFilter />}</Col>
      </Row>
      <UploadProject />
      <ExitIntentPopup />
    </div>
  )
}

export async function getStaticProps({ locale }: any) {
  return {
    props: { ...(await serverSideTranslations(locale, ['common'])) }
  }
}

export default memo(HomePage)
