import React from 'react'
import { Button, Result, Typography } from 'antd'
import { useTranslation } from 'next-i18next'
import { ReloadOutlined } from '@ant-design/icons'
const { Title } = Typography

interface PropsType {
  // eslint-disable-next-line no-unused-vars
  setIsResultsVisible: (value: boolean) => void
}

const RequestProposalResults: React.FC<PropsType> = (props) => {
  const { t } = useTranslation()

  return <div className='fade-animation'>
    <Result
      status='success'
      title={t('RequestProposal.Thankyou')}
      subTitle={<Title level={5} type={null}>
        {t('RequestProposal.ModalTitle')}
      </Title>}
      extra={[
        <Button
          key='go-back'
          onClick={() => props.setIsResultsVisible(false)}
          type='primary'
          icon={<ReloadOutlined spin/>}
        >
          {t('RequestProposal.MakeNew')}
        </Button>
      ]}
    />
  </div>
}

export default RequestProposalResults
