import { Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import { useExitIntent } from 'use-exit-intent'
import styles from './ExitIntentPopup.module.scss'

import { useTranslation } from 'next-i18next'
import ExitIntentNotLogged from './ExitIntentNotLogged/ExitIntentNotLogged'
import { useAppSelector } from 'src/app/hooks'
import { selectUser } from 'src/features/users/userSlice'
import ExitIntentLogged from './ExitIntentLogged/ExitIntentLogged'

const ExitIntentPopup: React.FC = () => {
  const { t } = useTranslation()

  const [open, setOpen] = useState(false)
  const [logged, setLogged] = useState(false)

  const isUserLogged = useAppSelector((state) => state.user.isUserLogged)
  const user = useAppSelector(selectUser)

  const siteKey = process.env.RECAPTCHA_PUBLIC_KEY

  const exitIntentPopup = useExitIntent({
    cookie: {
      daysToExpire: 7,
      key: 'exit-intent-rfp-news',
    },
    desktop: {
      triggerOnIdle: false,
      useBeforeUnload: false,
      triggerOnMouseLeave: true,
      delayInSecondsToTrigger: 1,
    },
    mobile: {
      triggerOnIdle: true,
      delayInSecondsToTrigger: 1,
    },
  })

  useEffect(() => {
    setLogged(isUserLogged && user.user_type === 'business')
  },[isUserLogged, user?.user_type])

  const showModal = () => {
    !logged && siteKey && setOpen(true)
    !siteKey && console.error('Error: captcha siteKey is not available')
  }

  exitIntentPopup.registerHandler({
    id: 'exitIntentRfpNews',
    handler: showModal,
  })

  const handleClose = () => {
    exitIntentPopup.unsubscribe()
    setOpen(false)
  }

  return (
    <>
      <Modal
        visible={open}
        title={
          <div className={styles['modal-title']}>
            <span>
              {logged
                ? t('exitIntentPopup.logged.modalTitle')
                : t('exitIntentPopup.notLogged.modalTitle')}
            </span>
          </div>
        }
        onOk={handleClose}
        onCancel={handleClose}
        width={600}
        bodyStyle={{ padding: '15px' }}
        centered={true}
        footer={null}
      >
        {logged ? (
          <ExitIntentLogged onClose={handleClose} />
        ) : (
          <ExitIntentNotLogged onClose={handleClose} />
        )}
      </Modal>
    </>
  )
}

export default ExitIntentPopup
