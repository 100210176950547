/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from 'next-i18next'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useAppSelector } from 'src/app/hooks'
import {
  setCategories,
  setCategorySelected,
} from 'src/features/compensation/compensationSlice'
import {
  setProjectKind,
  setRefreshFilters,
} from 'src/features/filters/filtersSlice'
import { capitalizeFirstLetter } from 'src/helpers/utils'
import styles from './CategoryFilter.module.scss'
import CategoryTag from './CategoryTag/CategoryTag'
import { setFiltersKeys } from 'src/features/ui/uiSlice'
import { checkCookies, trackEvent } from 'src/features/visitors/actions'

const CategoryFilter: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const projects = useAppSelector((state) => state.project.project)
  const portfolios = useAppSelector((state) => state.project.portfolios)
  const categories = useAppSelector((state) => state.compensation.categories)
  const refreshFilters = useAppSelector(
    (state) => state.filters.refreshFilters
  )
  const kindSelected = useAppSelector((state) => state.filters.projectKind)

  const getCategory = () => {
    let arrayOfCategories = []
    const projectArray = projects?.results?.map((project: any) =>
      project.kind.toLowerCase()
    )
    projectArray?.forEach((category: any) => {
      if (!arrayOfCategories.includes(capitalizeFirstLetter(category))) {
        arrayOfCategories.push(capitalizeFirstLetter(category))
      }
    })
    if(portfolios?.length>0){ arrayOfCategories.push('Portfolio') }
    dispatch(setCategories(arrayOfCategories.sort()))
  }

  const handleCategory = (kind: string) => {
    if(!kindSelected){
      dispatch(checkCookies())
      dispatch(trackEvent('MARKET_HOME', 'ACTION_CLICK', 'FILTER_PROJECTKIND'))
    }
    const isIn = kindSelected?.find((el) => el === kind)
    if (isIn) {
      const newFilter = kindSelected.filter((el) => el !== kind)
      dispatch(setProjectKind(newFilter))
    } else {
      dispatch(setProjectKind([...kindSelected, kind]))
    }
    dispatch(setFiltersKeys(['projectKind']))
    dispatch(setRefreshFilters(!refreshFilters))
  }

  const countProjects = (category: string) => {
    const projectsFiltered = projects?.results.filter(
      (project) => project.kind === category.toLowerCase()
    )
    return projectsFiltered.length
  }

  useEffect(() => {
    dispatch(setCategorySelected(''))
    getCategory()
  }, [projects, portfolios])

  return (
    <>
      <div data-testid='category-filter' className={styles['category-filter']}>
        {categories?.map((name: string, index) => {
          return (
            <div key={index}>
              <CategoryTag
                categoryName={name}
                handleCategory={handleCategory}
                countProjects={countProjects}
              />
            </div>
          )
        })}
      </div>
      <div className={styles['category-filter-mobile']}>
        <span className={styles['NameFilters']}>{t('category.filterMobile')}</span>
      </div>
    </>
  )
}

export default CategoryFilter
